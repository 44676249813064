:root {
    --nav-height: 16vh;
    --body-height: 84vh;

    --font-small: 12px;
    --font-normal: 16px;
    --font-medium: 20px;
    --font-large: 25px;
    --font-extra-large: 30px;

    --border-radius-small: 10px;
    --border-radius-normal: 15px;
    --border-radius-medium: 20px;
    --border-radius-large: 30px;
    --border-radius-larger: 40px;
    --border-radius-extra-large: 50px;
}
