:root {
    --color-gray: rgba(75, 73, 73, 0.363);
    --color-gray-low: #6e6e6e29;

    --color-primary: #007064;
    --color-secondary: #b69e2e;
    --color-tertiary: #78170f;
    --colo-white: #ffffff;
    --color-black: #000000;
    --color-font: #807f7f;
    --color-marker: #ff0000;
    --color-error: #ff0000;
}
