.a4Nfeq_container {
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #f0f8ff93;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.ygpXuG_containerNav {
  width: 100%;
  height: var(--nav-height);
}

.ygpXuG_containerNav .ygpXuG_header {
  width: 100%;
  height: 60%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_containerLogo {
  max-width: 600px;
  flex-direction: row;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_containerLogo .ygpXuG_logo {
  min-width: 80px;
  width: 100%;
  max-width: 300px;
  margin: 20px;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_containerLogo .ygpXuG_inamLogo {
  min-width: 80px;
  width: 100%;
  max-width: 200px;
  min-height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_containerLogo .ygpXuG_inamLogo__icon {
  width: 40%;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_containerLogo .ygpXuG_inamLogo__text {
  width: 60%;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_user {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--color-primary);
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 20px;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_user .ygpXuG_text {
  font-size: larger;
}

.ygpXuG_containerNav .ygpXuG_header .ygpXuG_user .ygpXuG_icon {
  width: 30px;
}

.ygpXuG_containerNav .ygpXuG_nav {
  width: 100%;
  height: 40%;
  background-color: var(--color-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_nav .ygpXuG_option {
  width: 50%;
  height: 100%;
  color: #ffffffc2;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  display: flex;
}

.ygpXuG_containerNav .ygpXuG_nav .ygpXuG_option__active {
  color: #fff;
  cursor: pointer;
  font-weight: bolder;
}

.ygpXuG_containerNav .ygpXuG_nav .ygpXuG_option:hover {
  color: #fff;
  cursor: pointer;
}

.ygpXuG_containerNav .ygpXuG_nav .ygpXuG_vl {
  height: 70%;
  border-left: 1px solid #000;
}

.ygpXuG_modalContent {
  width: auto;
  margin-top: 20px;
}

.ygpXuG_modalContent__icon {
  width: 30px;
  margin-right: 12px;
}

.Hn2k8G_modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0006;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.Hn2k8G_modal__active {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Hn2k8G_modal__inActive {
  display: none;
}

.Hn2k8G_modalContent {
  min-width: 260px;
  width: 35%;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 24px;
  margin: 15% auto;
  padding: 20px;
}

.Hn2k8G_close {
  color: #aaa;
  float: right;
  font-size: var(--font-extra-large);
  font-weight: bold;
}

.Hn2k8G_close:hover, .Hn2k8G_close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.Hn2k8G_title {
  color: var(--color-primary);
  width: 100%;
  font-size: var(--font-large);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  display: flex;
}

.CM0Nmq_button {
  color: var(--white);
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: .5em 0;
  display: flex;
}

.CM0Nmq_button__primary {
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__primary:hover, .CM0Nmq_button__primary:focus {
  opacity: .9;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__primaryOutLine {
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  background-color: #fff;
}

.CM0Nmq_button__primaryOutLine:hover, .CM0Nmq_button__primaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__secondary {
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  color: #fff;
}

.CM0Nmq_button__secondary:hover, .CM0Nmq_button__secondary:focus {
  opacity: .9;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  color: #fff;
}

.CM0Nmq_button__secondaryOutLine {
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  background-color: #fff;
}

.CM0Nmq_button__secondaryOutLine:hover, .CM0Nmq_button__secondaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  color: #fff;
}

.CM0Nmq_button__tertiary {
  border: 2px solid var(--color-tertiary);
  background-color: var(--color-tertiary);
  color: #fff;
}

.CM0Nmq_button__tertiary:hover, .CM0Nmq_button__tertiary:focus {
  opacity: .9;
  border: 2px solid var(--color-tertiary);
  background-color: var(--color-tertiary);
  color: #fff;
}

.CM0Nmq_button__tertiaryOutLine {
  border: 2px solid var(--color-tertiary);
  color: var(--color-tertiary);
  background-color: #fff;
}

.CM0Nmq_button__tertiaryOutLine:hover, .CM0Nmq_button__tertiaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-tertiary);
  background-color: var(--color-tertiary);
  color: #fff;
}

.CM0Nmq_button__success {
  color: #fff;
  background-color: #00c3a5;
  border: 2px solid #00c3a5;
}

.CM0Nmq_button__success:hover, .CM0Nmq_button__success:focus {
  opacity: .7;
}

.CM0Nmq_button__successOutLine {
  color: #fff;
  background-color: #00c3a5;
  border: 2px solid #00c3a5;
}

.CM0Nmq_button__successOutLine:hover, .CM0Nmq_button__successOutLine:focus {
  opacity: .7;
}

.CM0Nmq_button__warning {
  color: #fff;
  background-color: orange;
  border: 2px solid orange;
}

.CM0Nmq_button__warning:hover, .CM0Nmq_button__warning:focus {
  opacity: .7;
}

.CM0Nmq_button__danger {
  color: #fff;
  background-color: tomato;
  border: 2px solid tomato;
}

.CM0Nmq_button__danger:hover, .CM0Nmq_button__danger:focus {
  color: #fff;
  background-color: #f97a64;
  border: 2px solid #f97a64;
}

.CM0Nmq_button__dangerOutLine {
  color: tomato;
  background-color: #fff;
  border: 2px solid tomato;
}

.CM0Nmq_button__dangerOutLine:hover, .CM0Nmq_button__dangerOutLine:focus {
  color: #fff;
  background-color: tomato;
  border: 2px solid tomato;
}

.CM0Nmq_button__disabled {
  opacity: .5;
  cursor: not-allowed;
}

.CM0Nmq_button__xsmall {
  padding: 0 .5em;
  font-size: 1rem;
}

.CM0Nmq_button__small {
  padding: .5em;
  font-size: 1.4rem;
}

.CM0Nmq_button__medium {
  padding: .5em 0;
  font-size: 2rem;
}

.CM0Nmq_button__large {
  font-size: var(--font-large);
  padding: .5em;
  font-weight: bold;
}

.CM0Nmq_button__adaptable {
  width: 100%;
  height: 100%;
  font-size: 24px;
}

.UN0IJW_container {
  width: 100%;
  height: var(--body-height);
  justify-content: center;
  align-items: center;
  display: flex;
}

.FipinG_container {
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  flex-direction: column;
  display: flex;
}

.FipinG_container .FipinG_nav {
  width: 100%;
  height: 35vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.FipinG_container .FipinG_nav .FipinG_logo {
  width: 300px;
}

.FipinG_container .FipinG_nav .FipinG_inamLogo {
  width: 300px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  display: flex;
}

.FipinG_container .FipinG_nav .FipinG_inamLogo__icon {
  width: 88px;
  height: 80px;
}

.FipinG_container .FipinG_nav .FipinG_inamLogo__text {
  width: 140px;
  height: 120px;
}

.FipinG_container .FipinG_content {
  background-color: var(--color-primary);
  width: 100%;
  height: 65vh;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.n2B-bq_content {
  width: 100%;
  height: 100%;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.n2B-bq_content .n2B-bq_frame {
  width: 100%;
  height: 100%;
  background-color: #0000;
  border: none;
}

.r1P2za_container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.r1P2za_container .r1P2za_content {
  width: 280px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  display: flex;
}

.r1P2za_container .r1P2za_content .r1P2za_title {
  color: #fff;
  font-size: 24px;
}

.r1P2za_container .r1P2za_content .r1P2za_containerButton {
  width: 70%;
  height: 46px;
}

.wn4yba_error {
  width: 100%;
  color: tomato;
}

.PV3C8q_container {
  width: 100%;
  background-color: #fff;
  border-radius: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.PV3C8q_container .PV3C8q_input {
  width: 100%;
  height: 100%;
  font-size: var(--font-medium);
  color: #777;
  background-color: #0000;
  border: none;
  margin: 10px;
}

.PV3C8q_container .PV3C8q_input::placeholder {
  color: var(--color-gray);
}

.PV3C8q_container .PV3C8q_input:focus {
  border: none;
  outline: none;
}

.PV3C8q_container .PV3C8q_icon {
  width: 30px;
  height: 30px;
  color: var(--color-primary);
  margin: 5px 0 5px 16px;
}

.HoNXJW_container {
  width: 100%;
  background-color: #fff;
  border-radius: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.HoNXJW_container .HoNXJW_input {
  width: 100%;
  height: 100%;
  font-size: var(--font-medium);
  color: #777;
  background-color: #0000;
  border: none;
  margin: 10px;
}

.HoNXJW_container .HoNXJW_input::placeholder {
  color: var(--color-gray);
}

.HoNXJW_container .HoNXJW_input:focus {
  border: none;
  outline: none;
}

.HoNXJW_container .HoNXJW_icon {
  width: 30px;
  height: 30px;
  color: var(--color-primary);
  margin: 5px 0 5px 16px;
}

._4fNpMW_container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3.5em;
  display: flex;
}

.Gg2hfq_contentModal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.bHtCVW_container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bHtCVW_container .bHtCVW_mapContainer {
  width: 100%;
  height: 100%;
}

.bHtCVW_container .bHtCVW_button {
  max-width: 400px;
  width: 70%;
  z-index: 1;
  position: absolute;
  bottom: 60px;
}

.IElT5q_container {
  width: 100%;
  max-width: 900px;
  height: 100%;
}

.IElT5q_container .IElT5q_header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IElT5q_container .IElT5q_header .IElT5q_title {
  flex-direction: row;
  gap: 5px;
  margin: 15px;
  font-size: large;
  display: flex;
}

.IElT5q_container .IElT5q_header .IElT5q_title .IElT5q_text {
  color: var(--color-primary);
  font-weight: bolder;
}

.IElT5q_container .IElT5q_header .IElT5q_title .IElT5q_number {
  color: var(--color-secondary);
}

.IElT5q_container .IElT5q_header .IElT5q_return {
  background-color: var(--color-primary);
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  margin: 15px;
}

.IElT5q_container .IElT5q_header .IElT5q_return .IElT5q_returnIcon {
  margin: 10px;
}

.IElT5q_container .IElT5q_content {
  margin: 15px;
}

.uVnCya_header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.uVnCya_header .uVnCya_title {
  flex-direction: row;
  gap: 5px;
  margin: 15px;
  font-size: large;
  display: flex;
}

.uVnCya_header .uVnCya_title .uVnCya_text {
  color: var(--color-primary);
  font-weight: bolder;
}

.uVnCya_header .uVnCya_title .uVnCya_number {
  color: var(--color-secondary);
}

.uVnCya_header .uVnCya_return {
  background-color: var(--color-primary);
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  margin: 15px;
}

.uVnCya_header .uVnCya_return .uVnCya_returnIcon {
  margin: 10px;
}

.LlCoMG_container {
  width: 100%;
  height: 100%;
  max-width: 900px;
}

.LlCoMG_container .LlCoMG_content {
  min-height: 80%;
  flex-direction: column;
  gap: 10px;
  margin: 1em;
  display: flex;
}

.LlCoMG_container .LlCoMG_content .LlCoMG_title {
  color: var(--color-primary);
  width: 100%;
  margin-top: 10px;
  font-size: larger;
}

.LlCoMG_container .LlCoMG_content .LlCoMG_inputDate {
  height: 26px;
  width: auto;
  border: 1px solid var(--color-gray);
  color: gray;
  border-radius: 5px;
  padding: 7px;
}

.LlCoMG_container .LlCoMG_content .LlCoMG_textArea {
  width: auto;
  height: 130px;
  border: 1px solid var(--color-gray);
  resize: none;
  border-radius: 5px;
  padding: 10px;
}

.LlCoMG_container .LlCoMG_content .LlCoMG_textArea::placeholder {
  margin: 10px;
  padding: 10px;
  font-size: 12px;
}

.LlCoMG_container .LlCoMG_content .LlCoMG_textArea:focus {
  border: 1px solid var(--color-primary);
  outline: none;
}

.LlCoMG_container .LlCoMG_buttonsContainer {
  min-height: 200px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.LlCoMG_container .LlCoMG_buttons {
  width: 92%;
  max-width: 900px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.LlCoMG_container .LlCoMG_buttons .LlCoMG_button {
  width: 250px;
}

.LlCoMG_modalContent {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.LlCoMG_modalContent .LlCoMG_modalTexts {
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.LlCoMG_modalContent .LlCoMG_modalTexts .LlCoMG_modalText {
  color: var(--color-primary);
  font-size: large;
}

.LlCoMG_modalContent .LlCoMG_modalButtons {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  display: flex;
}

.LlCoMG_modalContent .LlCoMG_modalButtons .LlCoMG_modalButton {
  width: 70%;
  height: 50px;
}

._08wjiG_container {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  flex-direction: column;
  display: flex;
  overflow-y: scroll;
}

.MK42Kq_container {
  width: 100%;
  height: 100%;
  max-width: 900px;
}

.MK42Kq_container .MK42Kq_content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin: 20px;
  display: flex;
}

.MK42Kq_container .MK42Kq_content .MK42Kq_title {
  color: var(--color-primary);
}

.MK42Kq_container .MK42Kq_content .MK42Kq_scroll {
  width: 100%;
  height: 200px;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
  overflow: hidden;
}

.MK42Kq_container .MK42Kq_content .MK42Kq_scroll .MK42Kq_itemScroll {
  border-bottom: 2px solid var(--color-gray-low);
}

.MK42Kq_container .MK42Kq_content .MK42Kq_scroll .MK42Kq_itemScroll__text {
  color: #777;
  margin: 6px 10px;
}

body {
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color-gray: #4b49495d;
  --color-gray-low: #6e6e6e29;
  --color-primary: #007064;
  --color-secondary: #b69e2e;
  --color-tertiary: #78170f;
  --colo-white: #fff;
  --color-black: #000;
  --color-font: #807f7f;
  --color-marker: red;
  --color-error: red;
  --nav-height: 16vh;
  --body-height: 84vh;
  --font-small: 12px;
  --font-normal: 16px;
  --font-medium: 20px;
  --font-large: 25px;
  --font-extra-large: 30px;
  --border-radius-small: 10px;
  --border-radius-normal: 15px;
  --border-radius-medium: 20px;
  --border-radius-large: 30px;
  --border-radius-larger: 40px;
  --border-radius-extra-large: 50px;
  --z-level-1: 5;
  --z-level-2: 10;
  --z-level-3: 15;
  --z-level-4: 20;
  --z-level-5: 25;
}

/*# sourceMappingURL=index.c61cab2c.css.map */
